import React from "react";
import { useNavigate } from "react-router-dom";

const Billboard: React.FC = () => {
  const navigate = useNavigate();
  return (
    <section className="billboard-section">
      <div className="environment-wrapper">
        <div className="environment-sentence-wrapper">
          <p className="brands-subtitle">100+ brands already trust us</p>
          <p>Reimagining the way</p>
          <p>people come together.</p>
        </div>
        <button
          onClick={() => navigate("products")}
          className="seeproducts-btn"
        >
          SEE PRODUCTS
        </button>
      </div>
    </section>
  );
};

export default Billboard;
