import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { Center, OrbitControls } from "@react-three/drei";

// Models
import { Miniled } from "./Miniled.jsx";
import { Ledtiles } from "./Ledtiles.jsx";
gsap.registerPlugin(ScrollTrigger);

const CameraAnimation: React.FC = () => {
  const { camera } = useThree();

  useEffect(() => {
    if (!camera) return;

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".scene-canvas",
        start: "top top",
        end: "bottom+=1000 top",
        scrub: 1,
        pin: true,
      },
    });

    tl.to(camera.position, {
      x: 15,
      y: 10,
      z: -4,
      duration: 2,
      ease: "sine.inOut",
    })
      .from(
        ".step-one-text",
        {
          scale: 5,
          opacity: 0,
          duration: 1,
          ease: "sine.inOut",
        },
        "<"
      )
      .from(
        ".step-two-text",
        {
          scale: 5,
          opacity: 0,
          duration: 1,
          ease: "sine.inOut",
        },
        "-=.4"
      )
      .from(
        ".steptwo-img-element",
        {
          scale: 1.8,
          opacity: 0,
          duration: 1,
          ease: "sine.inOut",
        },
        "-=1.04"
      )
      .to(
        ".step-one-text",
        {
          opacity: 0,
          scale: 0,
          duration: 0.1,
        },
        "-=1"
      )
      .to(".step-two-text", {
        opacity: 0,
        scale: 0,
        duration: 0.1,
      })

      .to(".canvas-class", {
        opacity: 0,
        duration: 0.1,
      })
      .to(
        ".steptwo-img-element",
        {
          scale: 1,
          opacity: 0,
          duration: 0.1,
        },
        "<"
      )
      .from(".step-three-text", {
        opacity: 0,
        duration: 0.1,
      })
      .from(
        ".stepthree-img",
        {
          scale: 8,
          opacity: 0,
          duration: 1,
          ease: "sine.inOut",
        },
        "-=.35"
      );
  }, [camera]);

  useFrame(() => {
    camera.lookAt(0, 0, 0);
  });

  return null;
};

const StepsScene: React.FC = () => {
  const lightRef = useRef(null);
  const miniledRef = useRef(null);

  return (
    <div className="scene-canvas">
      <img alt="" src="/images/step3.png" className="stepthree-img" />
      <div className="steptwo-img-element" />
      <div className="glow-effect" />
      <div className="red-effect" />
      <p className="step-one-text step-text">Bring your idea</p>
      <p className="step-two-text step-text">Select your product</p>
      <p className="step-three-text step-text">We'll take care of the rest</p>
      <Canvas
        className="canvas-class"
        camera={{ position: [0, 0, -0.5] }}
        dpr={0.7}
      >
        <pointLight position={[4, 5, -4]} color={"red"} intensity={10} />
        <pointLight
          ref={lightRef}
          position={[1, 0, 1]}
          color={"red"}
          intensity={10}
        />
        <Ledtiles lightRef={lightRef} />
        <fog attach="fog" args={["black", 0, 5]} />
        <Center>
          <group ref={miniledRef}>
            <Miniled />
          </group>
        </Center>
        <CameraAnimation />
        <OrbitControls makeDefault enableZoom={false} enableRotate={false} />
      </Canvas>
    </div>
  );
};

export default StepsScene;
