import { FC, useState, useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { NavLink, useNavigate } from "react-router-dom";
import Hamburger from "../assets/svg/hamburger.svg?react";

const NavBar: FC = () => {
  const navigate = useNavigate();
  const isSmallDevice = useMediaQuery({ query: "(max-width: 800px)" });
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const navRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutsideNav = (event: MouseEvent) => {
    if (
      isSmallDevice &&
      isMobileNavOpen &&
      navRef.current &&
      event.target instanceof Node &&
      !navRef.current.contains(event.target)
    ) {
      setIsMobileNavOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideNav);

    // Cleanup: remove event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideNav);
    };
  }, [isSmallDevice, isMobileNavOpen]);

  return (
    <header className="header">
      <img
        onClick={() => navigate("/")}
        src="/images/2xlogo.png"
        alt="4th Canvas logo"
        className="nav-logo"
        draggable="false"
      />
      {isSmallDevice && (
        <button
          onClick={() => setIsMobileNavOpen(true)}
          className={
            isMobileNavOpen ? "mobile-nav-button disabled" : "mobile-nav-button"
          }
        >
          <Hamburger />
        </button>
      )}
      {!isSmallDevice ? (
        <nav className="nav">
          <ul className="nav__links">
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
            <li>
              <NavLink to="products">Products</NavLink>
            </li>
            <li>
              <NavLink to="case-studies">Case Studies</NavLink>
            </li>
            <li>
              <NavLink to="about">About</NavLink>
            </li>
            <li>
              <NavLink to="contact">Contact Us</NavLink>
            </li>
          </ul>
        </nav>
      ) : (
        <div
          className={isMobileNavOpen ? "nav-mobile active" : "nav-mobile"}
          ref={navRef}
        >
          <NavLink to="/" onClick={() => setIsMobileNavOpen(false)}>
            Home
          </NavLink>
          <NavLink to="products" onClick={() => setIsMobileNavOpen(false)}>
            Products
          </NavLink>
          <NavLink to="case-studies" onClick={() => setIsMobileNavOpen(false)}>
            Case Studies
          </NavLink>
          <NavLink to="about" onClick={() => setIsMobileNavOpen(false)}>
            About
          </NavLink>
          <NavLink to="contact" onClick={() => setIsMobileNavOpen(false)}>
            Contact Us
          </NavLink>
        </div>
      )}
    </header>
  );
};

export default NavBar;
