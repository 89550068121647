import React, { useEffect } from "react";
import useStore from "../../context/store";
import { motion } from "framer-motion";

// Data
import { transition } from "../../data/data";

// Assets
import CloseIcon from "../../assets/svg/close.svg?react";
import Location from "../../assets/svg/location.svg?react";

const CasePopup: React.FC = () => {
  const { selectedData, hidePopup, setIsPopupOpen } = useStore();

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setIsPopupOpen(false);
        setTimeout(() => {
          hidePopup();
        }, 500);
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [setIsPopupOpen, hidePopup]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ...transition, duration: 0.5 }}
      onClick={() => {
        setIsPopupOpen(false);
        setTimeout(() => {
          hidePopup();
        }, 1);
      }}
      className="case-popup-wrapper"
    >
      <motion.div
        initial={{ scale: 0.6 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0 }}
        transition={{ ...transition, duration: 0.5 }}
        style={{ backgroundImage: `url(${selectedData?.image})` }}
        onClick={(e) => e.stopPropagation()}
        className="case-popup"
      >
        <div className="case-popup-gradient" />
        <div className="case-popup-header">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ ...transition, delay: 0.2 }}
            className="case-popup-header__title"
          >
            {selectedData?.title}
          </motion.div>
          <button
            onClick={() => {
              setIsPopupOpen(false);
              setTimeout(() => {
                hidePopup();
              }, 500);
            }}
            className="case-popup-header__close"
          >
            <CloseIcon />
          </button>
        </div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ ...transition, delay: 0.35 }}
          className="case-popup__description"
        >
          <p>{selectedData?.desc}</p>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ ...transition, delay: 0.5 }}
          className="case-popup__content__location"
        >
          <Location /> {selectedData?.location}
        </motion.div>
        <div className="case-popup-gradient bottom-gradient" />
      </motion.div>
    </motion.div>
  );
};

export default CasePopup;
