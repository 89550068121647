interface CaseNavigation {
  button: string;
}

export interface CaseProps {
  caseName?: string;
  title?: string;
  location?: string;
  image?: string;
  desc?: string;
}

// Animation

export const transition = { duration: 1, ease: [0.6, 0.01, 0.05, 0.9] };

// CASE STUDIES DATA

export const caseNavigation: CaseNavigation[] = [
  {
    button: "EXHIBITIONS",
  },
  { button: "STORES" },
  { button: "OUTDOOR-MEDIA" },
  {
    button: "STADIUMS",
  },
  { button: "AMUSEMENT-PARKS" },
  { button: "LIVING-SPACES" },
  {
    button: "LOBBIES",
  },
];

export const stadiumProps: CaseProps[] = [
  {
    caseName: "STADIUMS",
  },
  {
    location: "SEOUL, KOREA",
    title: "Samsung Galaxy Unpacked",
    image: "/images/cases/stadium1.jpg",
    desc: "We have been producing Samsung's Unpacked product presentation events since 2010, including concept development, visual design, media production, and pioneering innovations like the world's first VR experience in 2016.",
  },
  {
    location: "BUSAN, KOREA",
    title: "BTS Yet to Come in BUSAN Robot Performance",
    image: "/images/cases/stadium2.jpg",
    desc: "At BTS's 'Yet to Come in BUSAN' concert, we showcased a futuristic fusion of Korean traditions and IT technology through a live performance featuring robots interpreting the rhythms of BTS's 'IDOL,' broadcast worldwide.",
  },
  {
    location: "LAS VEGAS, USA / BARCELONA, SPAIN",
    title: "Samsung Gear VR 4D experience, Gyro VR",
    image: "/images/cases/stadium3.jpg",
    desc: "As part of Samsung's Galaxy experience campaign, we introduced Gyro VR, a mobile VR attraction with 360-degree rotation synced with VR content. Praised by global media and popular at CES, it was later featured at MWC 2017 and the Samsung promotional center at the 2018 PyeongChang Olympics.",
  },
  {
    location: "INCHEON, KOREA",
    title: "Paradise City Club CHROMA Projection Mapping",
    image: "/images/cases/stadium4.jpg",
    desc: "Asia's largest media façade show on a 70-meter-tall club's exterior wall aimed to captivate customers across Paradise City's diverse spaces with its grand art-tainment scale.",
  },
];

export const exhibitionsProps: CaseProps[] = [
  {
    caseName: "EXHIBITIONS",
  },
  {
    location: "LVCC / LAS VEGAS, USA",
    title: "Hyundai MOBIS",
    image: "/images/cases/exhibition1.jpg",
    desc: "4th Canvas LED content development for CES 2022 Hyundai Mobis Pavilion, including urban shared mobility concepts in Joyful Life at M.VISION Town.",
  },
  {
    location: "SEOUL, KOREA",
    title: "K-POP Media Art - HYBE INSIGHT / SM Ent.",
    image: "/images/cases/exhibition2.jpg",
    desc: "4th Canvas oversees content for global artists BTS and TXT at HYBE Insight, enhancing their movements with dynamic lighting and artistic choreography. Additionally, responsible for the transition space, Intro: Portal, and various media art projects for SM Entertainment's office building.",
  },
  {
    location: "SEOUL, KOREA",
    title: "Seoul Motor Show - Unveiled Performance",
    image: "/images/cases/exhibition3.jpg",
    desc: "Hyundai vehicles showcased at Seoul Motor Show using vibrant LED screens controlled by robotic arms for an impressive unveiling performance.",
  },
  {
    location: "MILANO, ITALY",
    title: "Fuorisalone Embrace Sensorial Experience",
    image: "/images/cases/exhibition4.jpg",
    desc: "4th Canvas crafted kinetic media art using a large ring structure with 36 Samsung 65-inch TVs, showcasing dynamic, story-filled visuals at Fuorisalone in Italy",
  },
];

export const lobbiesProps: CaseProps[] = [
  {
    caseName: "LOBBIES",
  },
  {
    location: "SEOUL, KOREA",
    title: "Parc.1 NH Finance Tower DIGITAL ART WALL",
    image: "/images/cases/lobbie1.jpg",
    desc: "Park 1 NH Finance tower's 38x5m multi-faceted LED screen, enhanced with updated 8K HD video content, resembles a massive gallery artwork. Perfect for indoor spaces.",
  },
  {
    location: "SEOUL, KOREA",
    title: "5G Korea",
    image: "/images/cases/lobbie2.jpg",
    desc: "Mechanical robot extensions equipped with LED screens transform indoor lobbies into interactive spaces.",
  },
  {
    location: "SEOUL, KOREA",
    title: "Samsung",
    image: "/images/cases/lobbie3.jpg",
    desc: "Versatile kinetic displays, adaptable for showcasing optimal video content in various formats, thanks to its commercial-grade robotics technology, allowing free movement of monitors for use in main attractions, promotions, and more.",
  },
  {
    location: "SEOUL, KOREA",
    title: "Lotte World Tower - Media Stand",
    image: "/images/cases/lobbie4.jpg",
    desc: "Installation of Interactive Input Tablets at Lotte World Tower. By rotating the tablet, you can see Seoul from various angles with a self-sensor.",
  },
];

export const storesProps: CaseProps[] = [
  {
    caseName: "STORES",
  },
  {
    location: "San Francisco / Barcelona",
    title: "Galaxy S23 Portrait Studio",
    image: "/images/cases/store1.jpg",
    desc: "Samsung Galaxy S23 offers a studio-like automation solution for photo quality, followed by a multi-display gallery experience, showcasing ideas and problem-solving skills for influencers and customers.",
  },
  {
    location: "SEOUL, KOREA",
    title: "Hyundai Department Trade Center Media Façade",
    image: "/images/cases/store2.jpg",
    desc: "We leveraged the ultra-large curved LED screen to project interior space through graphic production and artwork. Additionally, we produce a range of public media art content, including 'Bell Tower' and 'H-Balloon,' enhancing Hyundai Department Store's atmosphere.",
  },
  {
    location: "New York/London/Berlin/San Francisco",
    title: "Robotics Solution - Galaxy Z Flip4 Bespoke",
    image: "/images/cases/store3.jpg",
    desc: "The Samsung Galaxy Z Flip4's Bespoke experience has evolved from robot-arm island to mobile-phone type, enabling direct product verification. It's exhibited at events like Unpacked, IFA, and in Samsung retail stores worldwide.",
  },
  {
    location: "SEOUL, KOREA",
    title: "Robot Barista Billie - Café Hey Bot, Hyundai Card Factory",
    image: "/images/cases/store4.jpg",
    desc: "The robot barista streamlines drink preparation and enhances store competitiveness through an unmanned sales model, optimizing the platform for various locations and sales methods.",
  },
];

export const amusementProps: CaseProps[] = [
  {
    caseName: "AMUSEMENT PARKS",
  },
  {
    location: "Canada/Iceland/G.Canyon/Chicago",
    title: "Robot Flying Theater",
    image: "/images/cases/amusement1.jpg",
    desc: "Flying Robot Theater combines cutting-edge technology and artistic performance for a unique aerial show experience.",
  },
  {
    location: "GYEONGGI, KOREA",
    title: "Everland Lenny’s Magic School Renewal",
    image: "/images/cases/amusement2.jpg",
    desc: "An example of transforming an old space into a 4D theater with increased accessibility through projection mapping and interaction, from scenarios to space planning and content production. We were in charge of all aspects except game production.",
  },
];

export const outdoorProps: CaseProps[] = [
  {
    caseName: "OUTDOOR MEDIA",
  },
  {
    location: "SEOUL, KOREA",
    title: "K-POP SQUARE Media Art",
    image: "/images/cases/outdoor1.jpg",
    desc: "At COEX Kpop Square, Sanghwa used anamorphic techniques in public media art to convey a hopeful message for 2022, integrating symbolism and encouraging pre-registration for Netmarble's 'Marvel Future Revolution' through immersive media art and commercial content.",
  },
  {
    location: "SEOUL, KOREA",
    title: "Lotte World Tower Media Art",
    image: "/images/cases/outdoor2.jpg",
    desc: "Our team designed content for Lotte World Tower's Sky Shuttle elevator, offering a time-lapse of Seoul's history and fireworks displays. Additionally, we produced media façade content for the tower's exterior, providing seasonal public art for Seoul residents to enjoy.",
  },
  {
    location: "SEOUL, KOREA",
    title: "Lotteria BGM Campaign in K-pop square",
    image: "/images/cases/outdoor3.jpg",
    desc: "Sanghwa joined Lotteria's 'Burger Becomes Music' campaign by showcasing a 3D video of Lotteria's iconic burger dancing to an AI-generated BGM on the COEX K-Pop Square billboard.",
  },
  {
    location: "SEOUL, KOREA",
    title: "Hyundai, Samseong-dong",
    image: "/images/cases/outdoor4.jpg",
    desc: "Our media art project, [The Zoo] from ART is your DUTY at Hyundai Department Store H.Wall, features delightful animal friends bringing joy to Samseong-dong.",
  },
];

export const livingSpacesProps: CaseProps[] = [
  {
    caseName: "LIVING SPACES",
  },
  {
    location: "SEOUL, KOREA",
    title: "Lotte World Tower",
    image: "/images/cases/livingspace1.jpg",
    desc: "Multi-screen virtual reality for an immersive experience.",
  },
  {
    location: "SEOUL, KOREA",
    title: "Samsung S7 VR",
    image: "/images/cases/livingspace2.jpg",
    desc: "Harness VR technology for interactive and immersive experiences, whether at home or in the workplace.",
  },
];

// PRODUCTS DATA

interface ProductProps {
  name: string;
  type: string | string[];
  asset: string;
  desc: string;
  isImage: boolean;
  longDesc: string;
}

export const productsData: ProductProps[] = [
  {
    name: "3D Billboard",
    type: ["interior", "exterior", "3dled"],
    asset: "/videos/3dbillboard.mp4",
    desc: "Explore the future of advertising with our LED 3D Billboards.",
    isImage: false,
    longDesc:
      "Explore the future of advertising with our LED 3D Billboards. These innovative displays create immersive and eye-catching experiences, taking your message to a whole new dimension. Discover how our technology transforms static ads into dynamic, attention-grabbing visual stories.",
  },
  {
    name: "2D Billboard",
    type: ["interior", "exterior"],
    asset: "/videos/2dscreens.mp4",
    desc: "Brilliantly showcasing your message with advanced LED technology.",
    isImage: false,
    longDesc:
      " Leveraging 4th Canvas's state-of-the-art robot control technology and comprehensive hardware development prowess, we've devised a system that pushes the boundaries of conventional filming equipment. This system enables intricate compositions, fluid movements, and repeated sequences that are difficult to achieve with traditional methods, adding a layer of dynamism and innovation to various forms of content, including movies, commercials, and music videos.",
  },
  {
    name: "360° RING DISPLAYS",
    type: ["interior"],
    asset: "/videos/360display.mp4",
    desc: "Step into a world of captivating visuals with our 360° ring displays.",
    isImage: false,
    longDesc:
      "These mesmerizing installations wrap your audience in a seamless visual experience. Explore how our cutting-edge technology turns any space into an immersive spectacle, perfect for events, retail, and more.",
  },
  {
    name: "INTERACTIVE / IMMERSIVE",
    type: ["interior", "exterior", "3dled"],
    asset: "/videos/interactive.mp4",
    desc: "Unlock captivating, tailored experiences with our LED panels and projectors.",
    isImage: false,
    longDesc: "Explore our immersive solutions for unparalleled engagement.",
  },
  {
    name: "KINETIC DISPLAY",
    type: ["interior", "exterior"],
    asset: "/videos/kineticdisplay.mp4",
    desc: "Revolutionize interaction with Kinetic Robot Arms linked to screens.",
    isImage: false,
    longDesc:
      "Revolutionize interaction with Kinetic Robot Arms linked to screens. Discover our cutting-edge solutions that seamlessly blend technology and creativity, making displays come to life like never before.",
  },
  {
    name: "COLUMNS",
    type: ["interior"],
    asset: "/videos/columns.mp4",
    desc: "Elevate spaces with LED column wraps and installations.",
    isImage: false,
    longDesc:
      "Transform ordinary columns into dynamic, eye-catching focal points. Explore our innovative solutions for captivating visual displays that leave a lasting impression.",
  },
  {
    name: "CURVED LED PANELS",
    type: ["interior", "exterior"],
    asset: "/videos/curved.mp4",
    desc: "Elevate your visual experiences with sleek and flexible displays.",
    isImage: false,
    longDesc: "Seamlessly adapt to any environment.",
  },
  {
    name: "MULTI-PANEL DISPLAYS",
    type: ["interior"],
    asset: "/videos/multidisplay.mp4",
    desc: "Experience seamless brilliance with multi-panel LED displays.",
    isImage: false,
    longDesc:
      "Our screens seamlessly connect to craft a unified visual masterpiece. Explore our innovative solutions for impactful and immersive displays in any setting.",
  },
  {
    name: "CEILING SCREENS",
    type: ["interior"],
    asset: "/videos/ceiling.mp4",
    desc: "Envision brilliance from above with ceiling LED screen solutions.",
    isImage: false,
    longDesc:
      "Elevate spaces with our innovative, customizable ceiling displays that captivate and inspire. Explore how we redefine the art of visual storytelling.",
  },
  {
    name: "INTERIOR MEDIA DISPLAYS",
    type: ["interior"],
    asset: "/videos/interior.mp4",
    desc: "Elevate interior spaces with media display solutions.",
    isImage: false,
    longDesc:
      "From offices to museums and galleries, discover how our innovative technology transforms interiors into captivating visual experiences. Explore the art of storytelling in every environment.",
  },
  {
    name: "TOWER MEDIA GRAPHICS",
    type: ["exterior"],
    asset: "/videos/towermedia.mp4",
    desc: "Elevate skyline storytelling with tower media graphics.",
    isImage: false,
    longDesc:
      "Experience the world's most iconic buildings transformed into visual canvases. Explore our stunning graphics on towering landmarks, creating breathtaking, larger-than-life narratives.",
  },
  {
    name: "PROJECTION MAPPING",
    type: ["interior", "exterior"],
    asset: "/videos/projectmapping.mp4",
    desc: "Unleash the power of projection mapping.",
    isImage: false,
    longDesc:
      "From transforming cityscapes on iconic buildings to creating immersive theatrical experiences, explore the limitless potential of our cutting-edge projection mapping solutions.",
  },
  {
    name: "ROBOT RIDE THEATER",
    type: ["interior"],
    asset: "/videos/robotflying.mp4",
    desc: "Immerse yourself in the future of entertainment at our robot ride theater.",
    isImage: false,
    longDesc:
      "Experience the thrill of motion and captivating visuals on a curved LED screen. Discover the ultimate fusion of technology and storytelling.",
  },
];
