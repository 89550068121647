import React from "react";
import ContactForm from "../contact/ContactForm";
// import { useNavigate } from "react-router-dom";

// Assets
// import Arrow from "../../assets/svg/arrow.svg?react";

// Types
interface CardProps {
  src: string;
}

type Card = CardProps[];

const cardsArray: Card = [
  {
    src: "images/cards/card1.jpg",
  },
  {
    src: "images/cards/card2.jpg",
  },
  {
    src: "images/cards/card3.jpg",
  },
  {
    src: "images/cards/card4.jpg",
  },
  { src: "images/cards/card5.jpg" },
  { src: "images/cards/card6.jpg" },
  { src: "images/cards/card7.jpg" },
  { src: "images/cards/card8.jpg" },
  { src: "images/cards/card9.jpg" },
  { src: "images/cards/card10.png" },
  { src: "images/cards/card11.jpg" },
  { src: "images/cards/card12.jpg" },
  { src: "images/cards/card13.jpg" },
  { src: "images/cards/card14.jpg" },
];

const Contact: React.FC = () => {
  return (
    <section className="contact-section">
      <div className="contact-form-wrapper">
        <ContactForm />
      </div>

      <div className="cards-section">
        <div className="top-gradient-element" />
        <div className="first-three-col">
          {cardsArray.slice(0, 3).map((card) => (
            <div
              key={card.src}
              style={{ backgroundImage: `url(${card.src})` }}
              className="card-element"
            />
          ))}
        </div>
        <div className="first-four-col">
          {cardsArray.slice(3, 7).map((card) => (
            <div
              key={card.src}
              style={{ backgroundImage: `url(${card.src})` }}
              className="card-element"
            />
          ))}
        </div>
        <div className="second-four-col">
          {cardsArray.slice(7, 11).map((card) => (
            <div
              key={card.src}
              style={{ backgroundImage: `url(${card.src})` }}
              className="card-element"
            />
          ))}
        </div>
        <div className="last-three-col">
          {cardsArray.slice(11, 14).map((card) => (
            <div
              key={card.src}
              style={{ backgroundImage: `url(${card.src})` }}
              className="card-element"
            />
          ))}
        </div>
        <div className="bottom-gradient-element" />
      </div>
    </section>
  );
};

export default Contact;
