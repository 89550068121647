import { FC, useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";
import { useMediaQuery } from "react-responsive";

import Landing from "../components/home/Landing";
import Cards from "../components/home/Cards";
import Steps from "../components/home/Steps";
import Billboard from "../components/home/Billboard";
import Contact from "../components/home/Contact";
import Footer from "../components/Footer";
import Experts from "../components/home/Experts";

gsap.registerPlugin(ScrollTrigger, SplitText);
const Home: FC = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  useEffect(() => {
    document.title = "4th Canvas";
  }, []);

  useEffect(() => {
    const tl = gsap.timeline();
    const tlTwo = gsap.timeline({
      scrollTrigger: {
        trigger: ".short-info-section",
        start: "top bottom-=200",
        end: "bottom center+=400",
      },
    });
    const tlThree = gsap.timeline({
      scrollTrigger: {
        trigger: ".contact-section",
        start: "top center",
        end: "bottom center+=400",
      },
    });

    window.scrollTo(0, 0);

    const landingTitleSplit = new SplitText(".landing-title", {
      type: isMobile ? "words" : "chars",
    });

    tl.from(isMobile ? landingTitleSplit.words : landingTitleSplit.chars, {
      duration: 1,
      y: 25,
      opacity: 0,
      stagger: 0.02,
      ease: "power4.inOut",
    })
      .from(".landing-subtitle", {
        opacity: 0,
      })
      .from(".trusted-by-wrapper", {
        opacity: 0,
      });

    gsap.from(".products-card", {
      duration: 1,
      y: 50,
      opacity: 0,
      stagger: 0.35,
      ease: "sine.inOut",
      scrollTrigger: {
        trigger: ".products-card",
        start: "top-=400 center",
        end: "bottom 80%",
      },
    });

    tlTwo
      .from(".dark-element", {
        backgroundColor: "rgba(0, 0, 0, 0)",
      })
      .from([".info-paragraph p", ".casestudies-btn"], {
        opacity: 0,
        duration: 0.5,
        y: 50,
        stagger: 0.2,
        ease: "sine.inOut",
      });

    tlThree.from(
      [
        ".first-three-col",
        ".first-four-col",
        ".second-four-col",
        ".last-three-col",
      ],
      {
        opacity: 0,
        duration: 2,
        y: 300,
        stagger: 0.4,
        ease: "sine.inOut",
      }
    );

    return () => {
      tl.kill();
      tlTwo.kill();
      tlThree.kill();
    };
  }, []);

  return (
    <>
      <main className="home-content">
        <Landing />
        <Cards />
        <Experts />
        <Steps />
        <Billboard />
        {/* <Works /> */}
        <Contact />
      </main>
      <Footer />
    </>
  );
};

export default Home;
