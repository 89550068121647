import React from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import StepsScene from "./steps-scene/StepsScene.jsx";

gsap.registerPlugin(ScrollTrigger);
const Steps: React.FC = () => {
  return (
    <section className="steps-section">
      <StepsScene />
    </section>
  );
};

export default Steps;
