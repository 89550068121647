import React, { useState, useEffect } from "react";
import gsap from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";

// Assets
import Arrow from "../assets/svg/arrow.svg?react";

gsap.registerPlugin(ScrollToPlugin);

const ScrollTop: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  const scrollTop = () => {
    gsap.to(window, { duration: 0.5, scrollTo: 0 });
  };

  useEffect(() => {
    const checkVisibility = () => {
      if (window.scrollY > 800) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", checkVisibility);

    return () => {
      window.removeEventListener("scroll", checkVisibility);
    };
  }, []);

  return (
    <button
      onClick={scrollTop}
      className={isVisible ? "scroll-top visible" : "scroll-top"}
    >
      <Arrow />
    </button>
  );
};

export default ScrollTop;
