// // src/store.ts
import { create } from "zustand";
import { productsData } from "../data/data";

interface DataProps {
  caseName?: string;
  title?: string;
  location?: string;
  image?: string;
  desc?: string;
}

interface ProductProps {
  name: string;
  type: string | string[];
  asset: string;
  desc: string;
  isImage: boolean;
  longDesc: string;
}

interface State {
  // Popup data
  selectedData: DataProps | null;
  showPopup: (data: DataProps) => void;
  hidePopup: () => void;

  // Selected product
  selectedProduct: ProductProps | null;
  setSelectedProduct: (product: ProductProps | null) => void;

  // Popup boolean
  isPopupOpen: boolean;
  setIsPopupOpen: (isPopupOpen: boolean) => void;

  // Form
  isFormSuccess: boolean;
  setIsFormSuccess: (isFormSuccess: boolean) => void;

  // Filtered products
  activeFilter: string;
  filteredProducts: ProductProps[];
  setActiveFilter: (filter: string) => void;
}

const useStore = create<State>((set) => ({
  isPopupOpen: false,
  setIsPopupOpen: (isPopupOpen) => set({ isPopupOpen }),

  selectedProduct: null,
  setSelectedProduct: (selectedProduct: ProductProps | null) =>
    set({ selectedProduct }),
  selectedData: null,

  showPopup: (data) => set({ selectedData: data }),
  hidePopup: () => set({ selectedData: null }),

  isFormSuccess: false,
  setIsFormSuccess: (isFormSuccess) => set({ isFormSuccess }),

  activeFilter: "all",
  filteredProducts: productsData,
  setActiveFilter: (filter) =>
    set(() => {
      const filteredProducts = productsData.filter((product) =>
        filter === "all" ? true : product.type.includes(filter)
      );
      return { activeFilter: filter, filteredProducts };
    }),
}));

export default useStore;
