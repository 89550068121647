import { useMediaQuery } from "react-responsive";

type CardProps = {
  product: string;
  imgSrc: string;
  video: string;
};

type CardArr = CardProps[];

const cardData: CardArr = [
  {
    product: "Interior",
    imgSrc: "/images/interiorcardbg.png",
    video: "/videos/interior.mp4",
  },
  {
    product: "Exterior",
    imgSrc: "/images/exteriorcardbg.png",
    video: "videos/exterior.mp4",
  },
  {
    product: "3D LED",
    imgSrc: "/images/3dledcard.png",
    video: "videos/3dbillboard.mp4",
  },
];

const Cards: React.FC = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 850px)" });

  const handleMouseEnter = (index: number) => {
    const video: HTMLVideoElement | null = document.querySelector(
      `.card-video-${index}`
    );
    if (video) video.play();
  };

  const handleMouseLeave = (index: number) => {
    const video: HTMLVideoElement | null = document.querySelector(
      `.card-video-${index}`
    );
    if (video) video.pause();
  };

  return (
    <section className="cards-section-home">
      <div className="card-wrapper">
        {cardData.map((card, index) => {
          return (
            <div
              key={card.product}
              style={{ backgroundImage: `url(${card.imgSrc})` }}
              className={`products-card products-card-${index}`}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={() => handleMouseLeave(index)}
            >
              <div className="darker-gradient-element" />
              <video
                autoPlay={isMobile}
                playsInline
                muted
                className={`card-video-${index}`}
              >
                <source src={card.video} type="video/mp4" />
              </video>
              <p className="product-name">{card.product}</p>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Cards;
