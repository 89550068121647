import { useEffect, lazy, Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import gsap from "gsap";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import useStore from "./context/store";

// Components
import NavBar from "./components/NavBar";
import CasePopup from "./components/case-study/CasePopup";

// Pages
import Home from "./pages/Home";
import { AnimatePresence } from "framer-motion";
import ProductsFilter from "./components/products/ProductsFilter";
import ScrollTop from "./components/ScrollTop";
import SuccessForm from "./components/contact/SuccessForm";

const ProductsPopup = lazy(() => import("./components/products/ProductsPopup"));
const Products = lazy(() => import("./pages/Products"));
const Contact = lazy(() => import("./pages/Contact"));
const About = lazy(() => import("./pages/About"));
const CaseStudy = lazy(() => import("./pages/CaseStudy"));

type RouteConfig = {
  path: string;
  component: React.ComponentType;
};

type RoutesConfig = RouteConfig[];

gsap.registerPlugin(ScrollSmoother);
function App() {
  const { isPopupOpen, isFormSuccess } = useStore();
  const location = useLocation();

  const routes: RoutesConfig = [
    {
      path: "/",
      component: Home,
    },
    {
      path: "/contact",
      component: Contact,
    },
    {
      path: "/about",
      component: About,
    },
    {
      path: "/case-studies",
      component: CaseStudy,
    },
    {
      path: "/products",
      component: Products,
    },
    {
      path: "/products/:productName",
      component: ProductsPopup,
    },
  ];

  useEffect(() => {
    ScrollSmoother.create({
      smooth: 1.1,
      effects: true,
      smoothTouch: 0.1,
    });
  }, []);

  return (
    <div id="smooth-wrapper">
      <NavBar />

      <AnimatePresence mode="wait">
        {isPopupOpen && <CasePopup />}
      </AnimatePresence>

      {location.pathname.startsWith("/products") && <ProductsFilter />}

      {(location.pathname.startsWith("/products") ||
        location.pathname.startsWith("/case-studies")) && <ScrollTop />}

      <AnimatePresence>{isFormSuccess && <SuccessForm />}</AnimatePresence>
      <div id="smooth-content">
        <Routes>
          {routes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <Suspense fallback={null}>
                  <route.component />
                </Suspense>
              }
            />
          ))}
        </Routes>
      </div>
    </div>
  );
}

export default App;
