import { FC } from "react";
import Marquee from "react-fast-marquee";
import LazyLoad from "react-lazy-load";

// SVGs
import Hyundai from "../../assets/svg/hyundai.svg?react";
import Samsung from "../../assets/svg/samsung.svg?react";
import SKTelecom from "../../assets/svg/sktelecom.svg?react";

const Landing: FC = () => {
  return (
    <>
      <section className="home-landing">
        <div className="filter-element" />

        <div className="landing-title-wrapper">
          <p className="landing-subtitle">
            THE MOST CREDIBLE Custom LED displays in the world{" "}
          </p>
          <p className="landing-title">New experiences</p>
          <p className="landing-title">New technology</p>
        </div>

        <LazyLoad>
          <video
            playsInline
            autoPlay
            muted
            loop
            className="landing-video"
            poster="/images/main-poster.jpg"
          >
            <source src="/videos/shortervideo.mp4" type="video/mp4" />
          </video>
        </LazyLoad>

        <div className="trusted-by-wrapper">
          <p className="trusted-by">TRUSTED BY</p>
          <Marquee
            style={{ userSelect: "none" }}
            speed={30}
            autoFill
            className="info-section-marquee"
          >
            <div className="marquee-dot" />
            <Hyundai />
            <div className="marquee-dot" />
            <Samsung />
            <div className="marquee-dot" />
            <SKTelecom />
            <div className="marquee-dot" />
            <img src="/images/bts.png" alt="BTS logo" />
          </Marquee>
        </div>
      </section>
    </>
  );
};

export default Landing;
