import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Miniled(props) {
  const { nodes, materials } = useGLTF("/models/miniled.glb");

  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.defaultMaterial.geometry}
        material={materials.phong3SG}
        position={[0, 0, 0]}
        rotation={[0.325, 0.036, -0.425]}
      />
    </group>
  );
}


