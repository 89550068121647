import React from "react";
import useStore from "../../context/store";
import { motion } from "framer-motion";

// Assets
import Success from "../../assets/svg/success.svg?react";
import { transition } from "../../data/data";

const SuccessForm: React.FC = () => {
  const { setIsFormSuccess } = useStore();

  return (
    <motion.div
      exit={{ opacity: 0 }}
      onClick={() => {
        setIsFormSuccess(false);
      }}
      className="success-form"
    >
      <motion.div
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0 }}
        transition={transition}
        onClick={(e) => e.stopPropagation()}
        className="success-form-popup"
      >
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ ...transition, delay: 0.1 }}
          className="success-wrapper"
        >
          <Success className="success-icon" />
        </motion.div>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ ...transition, delay: 0.2 }}
        >
          Thank you for submitting a request. Please allow us 24 hours to
          respond.
        </motion.p>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ ...transition, delay: 0.3 }}
        >
          Together, we can illuminate the world.
        </motion.p>
        <motion.button
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ ...transition, delay: 0.4 }}
          onClick={() => {
            setIsFormSuccess(false);
          }}
          className="success-close-btn"
        >
          Close
        </motion.button>
      </motion.div>
    </motion.div>
  );
};

export default SuccessForm;
