import React from "react";
import { useNavigate } from "react-router-dom";

const Experts: React.FC = () => {
  const navigate = useNavigate();
  
  return (
    <section className="short-info-section">
      <video playsInline muted autoPlay loop className="experts-video">
        <source src="/videos/experts-video.mp4" type="video/mp4" />
      </video>
      <div className="dark-element" />
      <div className="experts-section-info-wrapper">
        <div className="info-paragraph">
          <p>Individually crafted for every environment</p>
          <p>Custom displays perfectly</p>
          <p>designed for your unique vision</p>{" "}
          <button
            onClick={() => navigate("case-studies")}
            className="casestudies-btn"
          >
            CASE STUDIES
          </button>
        </div>
      </div>
    </section>
  );
};

export default Experts;
