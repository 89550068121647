import React, { useRef } from "react";
import { useGLTF, useMatcapTexture } from "@react-three/drei";
import { useControls } from "leva";

export function Ledtiles({ ...props }) {
  const { nodes, materials } = useGLTF("/models/ledtiles.glb");

  // const { positionX, positionY, positionZ, rotationX, rotationY, rotationZ } =
  //   useControls("Ledtiles", {
  //     positionX: {
  //       value: 4,
  //       min: -10,
  //       max: 10,
  //       step: 0.1,
  //     },
  //     positionY: {
  //       value: 2,
  //       min: -10,
  //       max: 10,
  //       step: 0.1,
  //     },
  //     positionZ: {
  //       value: -1.5,
  //       min: -10,
  //       max: 10,
  //       step: 0.1,
  //     },
  //     rotationX: {
  //       value: 1.8,
  //       min: -10,
  //       max: 10,
  //       step: 0.1,
  //     },
  //     rotationY: {
  //       value: 0.7,
  //       min: -10,
  //       max: 10,
  //       step: 0.1,
  //     },
  //     rotationZ: {
  //       value: 1.2,
  //       min: -10,
  //       max: 10,
  //       step: 0.1,
  //     },
  //   });
  // position={[positionX, positionY, positionZ]}
  //   rotation={[rotationX, rotationY, rotationZ]}
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.defaultMaterial001.geometry}
        material={materials.phong4SG}
        position={[3.9, 2.8, -1.4]}
        scale={8}
        rotation={[1.8, 0.7, 1.2]}
      />
      <mesh
        geometry={nodes.defaultMaterial001.geometry}
        material={materials.phong4SG}
        position={[4.6, 2.0, -1.595]}
        scale={8}
        rotation={[1.78, 0.65, 1.2]}
      />
      <mesh
        geometry={nodes.defaultMaterial001.geometry}
        material={materials.phong4SG}
        position={[3.2, 3.6, -1.2]}
        rotation={[1.8, 0.7, 1.2]}
        scale={8}
      />
    </group>
  );
}
