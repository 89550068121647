import React, { useState } from "react";
import useStore from "../../context/store";

// Assets
import Info from "../../assets/svg/info.svg?react";
import Filter from "../../assets/svg/filter.svg?react";

const ProductsFilter: React.FC = () => {
  const { activeFilter, setActiveFilter } = useStore();
  const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false);

  const filterButton = (filteredProducts: string): any => {
    setActiveFilter(filteredProducts);
  };

  const getButtonClass = (buttonType: string) => {
    return activeFilter === buttonType
      ? `${buttonType}-button active`
      : `${buttonType}-button`;
  };

  return (
    <>
      <div
        onClick={() => setIsMobileFilterOpen(true)}
        className="product-filter-btn-mobile"
      >
        <Filter />
      </div>

      <nav
        className={
          isMobileFilterOpen ? "products-filter mobile" : "products-filter"
        }
      >
        <div className="product-filter-title">
          <Filter />
          <p>Filter</p>
        </div>
        <div className="products-filter-bottom">
          <button
            onClick={() => filterButton("all")}
            className={getButtonClass("all")}
          >
            All
          </button>
          <button
            onClick={() => filterButton("interior")}
            className={getButtonClass("interior")}
          >
            Interior
          </button>
          <button
            onClick={() => filterButton("exterior")}
            className={getButtonClass("exterior")}
          >
            Exterior
          </button>
          <button
            onClick={() => filterButton("3dled")}
            className={getButtonClass("3dled")}
          >
            3D LED
          </button>
        </div>

        <div className="navigate-info">
          <Info />
          <p>Click to filter products.</p>
        </div>
      </nav>
    </>
  );
};

export default ProductsFilter;
