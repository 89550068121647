import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Marquee from "react-fast-marquee";

import Arrow from "../assets/svg/arrow.svg?react";

interface FooterProps {
  isContactPage?: boolean;
}

const Footer: React.FC<FooterProps> = ({ isContactPage = false }) => {
  const navigate = useNavigate();

  return (
    <footer className="footer">
      <div className="footer-darklinear-element" />

      <div className="contact-section-wrapper">
        <div className="contact-section-left">
          <p>Bright</p>
          <p>ideas</p>
          <p>ahead.</p>
        </div>
        <div className="contact-section-right">
          <button onClick={() => navigate("/contact")} className="contact-btn">
            <Arrow className="button-arrow-icon" />
            Get in touch
          </button>
        </div>
      </div>

      <div className="footer-top">
        <div className="footer-top-left">
          <img
            draggable="false"
            className="footer-logo"
            src="/images/2xlogo.png"
            alt="4th Canvas footer logo"
          />
          <div className="footer-rights-reserved">
            <p>©2023 4th CANVAS</p>
            <p>ALL RIGHTS RESERVED</p>
          </div>
        </div>
        <div className="footer-top-center">
          <p>4752 N Avers Ave,</p>
          <p>Chicago,</p>
          <p>IL 60625</p>
        </div>
        <div className="footer-top-right">
          <p className="bold-text">CONTACT US</p>
          <p>+82-3287-1800</p>
          <Link to="mailto:contact@4thcanvas.com">contact@4thcanvas.com</Link>
        </div>
      </div>

      {!isContactPage && (
        <Marquee
          style={{ marginTop: "3.2rem" }}
          autoFill
          className="contact-usa-marquee"
        >
          <p>ILLUMINATE YOUR VISION</p>
          <div className="dot-element" />
        </Marquee>
      )}
    </footer>
  );
};

export default Footer;
