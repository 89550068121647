import React from "react";
import { Formik, Field, Form, FormikHelpers, ErrorMessage } from "formik";
import emailjs from "@emailjs/browser";
import * as yup from "yup";
import useStore from "../../context/store";

// Assets
import Arrow from "../../assets/svg/arrow.svg?react";

// Types
interface Values {
  name: string;
  email: string;
  message: string;
  phone: string;
}

const ContactForm: React.FC = () => {
  const { setIsFormSuccess } = useStore();

  const sendEmail = (
    values: Values,
    { setSubmitting }: FormikHelpers<Values>
  ) => {
    const { name, email, message, phone } = values;

    // Replace with your EmailJS User ID, Service ID, and Template ID
    const EMAILJS_SERVICE_ID = "service_4f5q9ko";

    // Create the email parameters
    const emailParams = {
      from_name: name,
      from_email: email,
      to_email: "ogzhntfnk@gmail.com", // Replace with the recipient's email address
      to_name: "4th Canvas", // Replace with the recipient's name
      phone: phone,
      message: message,
    };

    // Send the email using EmailJS
    emailjs
      .send(
        EMAILJS_SERVICE_ID,
        "template_3bmrjor",
        emailParams,
        "7_AxtqQ-4LIs80dbE"
      )
      .then((response) => {
        console.log("Email sent successfully:", response);

        // Optionally, you can show a success message to the user
        setIsFormSuccess(true);
        // clean up the form
        values.name = "";
        values.email = "";
        values.message = "";
        values.phone = "";

        // Reset the form and setSubmitting to false
        setSubmitting(false);
      })
      .catch((error) => {
        console.error("Email error:", error);
        // Optionally, you can show an error message to the user
        alert("Failed to send email. Please try again later.");
        // SetSubmitting to false
        setSubmitting(false);
      });
  };

  const validationSchema = yup.object({
    name: yup.string().required("Name is required"),
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is required"),
    phone: yup.string().required("Phone number is required"),
    message: yup.string().required("Message is required"),
  });

  return (
    <div className="contact-form">
      <p className="contact-form-title">CONTACT FORM</p>

      <Formik
        initialValues={{
          name: "",
          email: "",
          message: "",
          phone: "",
        }}
        validationSchema={validationSchema}
        onSubmit={sendEmail} // Use the sendEmail function
      >
        <Form className="contact-form-inputs">
          <ErrorMessage
            name="name"
            component="div"
            className="contact-form-error"
          />

          <Field id="name" name="name" placeholder="Name" />
          <ErrorMessage
            name="email"
            component="div"
            className="contact-form-error"
          />
          <Field id="email" name="email" placeholder="Email" type="email" />
          <ErrorMessage
            name="phone"
            component="div"
            className="contact-form-error"
          />
          <Field
            id="phone"
            name="phone"
            placeholder="Phone"
            type="number"
            pattern="\d+"
          />
          <ErrorMessage
            name="country"
            component="div"
            className="contact-form-error"
          />
          <Field id="message" name="message" placeholder="Message or question">
            {({ field }: any) => (
              <div>
                <textarea
                  id="message"
                  rows={5}
                  cols={97.5}
                  {...field}
                  placeholder="Project details or request"
                />
              </div>
            )}
          </Field>

          <div className="contact-btns-wrapper">
            <button className="contact-form-btn" type="submit">
              SUBMIT <Arrow className="contact-arrow-icon" />
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default ContactForm;
